<template>
  <div class="disifang">
    <div class="font">
      <p class="font1" v-if="showView">授权成功</p>
      <p class="font1" v-else>授权失败</p>
      该页还<span>{{current}}</span>秒自动跳回授权页
      <el-button type="text" @click="to" v-if="showBtn">立即跳回</el-button>
    </div>
    
  </div>
</template>

<script>
import { disi } from './expressApi'
export default {
  data() {
    return {
      current: 5,
      showBtn: false,
      showView: true
    }
  },
  components: {

  },
  methods: {
    reduce(i) {
      i = this.current
      let timer = setInterval(() => {
        if(i == 0) {
          clearInterval(timer)
          console.log(this);
          this.$router.push('/fedex/had')
        }
        this.current = i
        i--
      }, 1000)
    },
    to() {
      this.$router.push('/fedex/had')
    },
    async getQuery() {
      const {id, code} = this.$route.query
      const {detail, code: status} = await disi({
        id,
        code
      })
      if(status == 0){
        this.showView = detail
        this.showBtn = true
      }
      
    }
  },
  async created() {
    this.reduce()
    this.getQuery()
  }
}
</script>

<style scoped lang="scss">
.disifang {
  height: 94vh;
  position: relative;
  .font {
    position: absolute;
    font-size: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-100%);
    span {
      display: inline-block;
      margin: 0 20px;
      color: red;
    }
    .font1 {
      text-align: center;
    }
  }
}
</style>
